<template>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="`/${home}`">
          <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Recoil</b-breadcrumb-item>
      </b-breadcrumb><br>
      <b-card title="Recoil">
        <b-modal size="sm" hide-footer
            id="promptMultipleDelete"
            ref="modal"
            v-model="promptMultipleDelete"
            title="Delete Selected Recoil"
          >
          <p>Are you sure that you want to permanently delete selected Recoil?</p>
          <b-button size="sm" variant="success" @click="promptMultipleDelete = false" style="float: right">Cancel</b-button>
          <b-button size="sm" variant="danger" @click="multipleDelete" style="float: right; margin-right:10px;">Delete</b-button>
        </b-modal>
        <b-modal size="sm" hide-footer
            id="promptDelete"
            ref="modal"
            v-model="promptDelete"
            title="Delete Recoil"
          >
          <p>Are you sure that you want to permanently delete selected Recoil?</p>
          <b-button size="sm" variant="success" @click="promptDelete = false" style="float: right">Cancel</b-button>
          <b-button size="sm" variant="danger" @click="confirmDelete" style="float: right; margin-right:10px;">Delete</b-button>
        </b-modal>

        <!-- Upload Recoil modal-->
        <b-modal
          id="promptUpload"
          ref="modal"
          v-model="promptUpload"
          title="Upload"
        >
          <div class="upload-border">
            <feather-icon icon="UploadIcon" size='4x'/><br>
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFiles"
              style="margin: 15px 20px;"
            ></b-form-file>
            <strong v-if="errorMessage" style="color:red; text-align:right;">{{ errorMessage }}</strong>
          </div>

          <template #modal-footer>
          <b-button
            size="md"
            variant="success"
            :disabled="errorMessage !== '' || file === null || uploadItemDisabled"
            @click="uploadItem"
          >
            Upload
          </b-button>
          <b-button
            size="md"
            variant="danger"
            @click="promptUpload=false"
          >
            Cancel
        </b-button>
        </template>
      </b-modal>

      <b-modal
        id="promptLoading"
        ref="modal"
        v-model="promptLoading"
        title="Processing..."
        hide-footer
      >
        <div class="progress" style="height: 30px; margin: 20px 0;">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="uploadCounter * 100 / uploadLength" aria-valuemin="0" aria-valuemax="100" :style="`width: ${uploadCounter * 100 / uploadLength}%`">
            <span class="progress-bar-title">{{ uploadCounter }} / {{ uploadLength }}</span>
          </div>
        </div>
      </b-modal>

        <b-modal v-if="dataApproval"
          id="promptApproval"
          ref="modal"
          v-model="promptApproval"
          title="Approval"
          hide-footer>
          <div v-if="dataApproval.props.status === 'New Recoil Pending'">
            <b-button variant="success" size="sm" block @click="approve(dataApproval); promptApproval=false;">Approve</b-button>
            <b-button variant="danger" size="sm" block @click="reject(dataApproval); promptApproval=false;">Reject</b-button>
          </div>
        </b-modal>
        <b-col>
          <b-row class="show-on-mobile">
            <b-col cols="6" style="margin-bottom:15px;">
              <b-form-group
                label="Sort By"
                label-for="sort-by-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
  
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :options="directionOptions"
                    size="sm"
                    class="w-25"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
  
        <b-col cols="12">
          <b-row style="margin-bottom: 15px">
            <b-col cols="8">
              <b-button
                variant="success"
                @click="uploadButton"
                style="margin-left: 15px"
              >
                Upload Recoil
              </b-button>
            </b-col>
  
            <b-col cols="4">
              <div>
                <b-row>
                  <span class="spin" v-if="showSpinner"></span>
                </b-row>
                <b-form-input
                  v-model="search"
                  @input="debounceSearch"
                  type="search"
                  placeholder="Type to Search"
                  style="float: right;"
                />
              </div>
            </b-col>
          </b-row>
          <b-row style="margin-bottom:10px" class="show-on-mobile">
            <b-col>
              <b-form-checkbox
                v-model="stackedStatus"
                value="md"
                unchecked-value= false
              >
                Stacked Table
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div style="overflow-x: visible;">
                <b-table
                  :select-mode="selectMode"
                  @row-selected="onRowSelected"
                  selectable
                  :filter="search"
                  hover
                  head-variant="dark"
                  responsive
                  outlined
                  :items="pickLists"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  small
                  :stacked= stackedStatus
                >
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-dropdown variant="success" size="sm" text="Menu" no-caret>
                      <template #button-content>
                        <feather-icon size="1x" icon="MenuIcon" />
                      </template>
                      <b-dropdown-item>
                        <b-button variant="success" size="sm" :to="{ name: 'view-recoil', params: { id: row.item.id }, }" style="margin-right:10px"><feather-icon size="1x" icon="EyeIcon" /></b-button>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-row>
  
          <b-row>
            <b-col cols="4" class="pt-1">
              <b-form-group
                label="Data Per Page"
                label-for="per-page-select"
                label-cols-md="0"
                label-align-sm="left"
                label-size="md"
                class="mb-0"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  @input="queryRecoil()"
                ></b-form-select>
              </b-form-group>
            </b-col>
              <b-col class="pt-1">
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  @change="onPageChange"
                  :total-rows="totalRows"
                  first-number
                  last-number
                  class="float-right"
                  aria-controls="user-table"
                ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </div>
  </template>
  <script>
  import { dateFormat, userAccess, viewAccess } from '@/utils/utils.js';
  import { mapActions, mapState } from "vuex";
  import * as XLSX from 'xlsx'
  
  export default {
    data() {
      return {
        form: {
          orderNumber: "",
          requestNumber: "",
          receiver: "",
          createdAt: "",
          item: [],
          props: "",
          grandTotal:""
        },
        addModal: {
          orderNumber: "",
          picklistNo: "",
          picklistLine: "",
          orderDate: "",
          pickListType: "",
          sendType: "",
          quantity: "",
          toBatch: "",
          fromBatch: "",
          orderHandler: "",
          lineItem: "",
          lineDescription: "",
          unit: "",
          stockBalance: 0,
          binLocation: "",
          warehouse: "",
          props: {}
        },
        stackedStatus:"md",
        showSpinner: false,
        selectMode: "multi",
        selected: [],
        lastPicklistLines: {},
        // picklistLineCounter: 1,
        picklistNoCounter: 1,
        promptMultipleDelete: false,
        multipleDeleteState: false,
        promptDelete: false,
        promptApproval:false,
        uploadItemDisabled:false,
        promptFinish:false,
        dataApproval:'',
        deleteId: "",
        search: null,
        sequence: 1,
        perPage: 5,
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        idCadang: "",
        fields: [
          {
            key: "lineItem",
            label: "Line Item",
            filterable: true,
            sortable: true,
          },
          {
            key: "orderNumber",
            label: "SO Number",
            filterable: true,
            sortable: true,
          },
          {
            key: "fromBatch",
            label: "From Batch",
            filterable: true,
            sortable: true,
          },
          {
            key: "quantity",
            label: "Qty",
            filterable: true,
            sortable: true,
          },
          {
            key: "unit",
            label: "Unit",
            filterable: true,
            sortable: true,
          },
          {
            key: "props.statusNo",
            label: "Status no",
            filterable: true,
            sortable: true,
          },
          // {
          //   key: "createdAtFormatted",
          //   label: "created At",
          //   filterable: true,
          //   sortable: true,
          // },
          {
            key: "actions",
            label: "Actions",
          },
        ],
        //for download and upload Recoil
        promptUpload: false,
        filter: {
          'Name'      : "name",
          'Created At': 'createdAtFormatted',
          'Props'     : 'props',
        },
        file: null,
        uploadCounter: 0,
        uploadLength: 0,
        errorMessage: '',
        uploadArray: [],
        promptLoading: false,
        overwrite: false,
        //sort direction list
        directions: [
          { key: false, label: 'Asc', sortable: true },
          { key: true, label: 'Desc', sortable: true },
        ],
        pageOptions: [5, 10, 20, 100],
        sortBy: '',
        sortDesc: false,
      };
    },
    computed: {
      permission() {
        return userAccess('Recoil')
      },
      home() {
        return viewAccess()[0]
      },
      buttonState() {
        if (this.selected.length > 1) {
          return (this.multipleDeleteState = true);
        }
      },
      pickLists() {
        var data = this.$store.getters["picklist/getPickListRecoil"]
        .map((el) => {
          return{
            ...el,
            picklistNumber: el.picklistNo + "_" + el.picklistLine,
            lineItem: el.lineItem + ", " + el.lineDescription
          }
          });
        return data
      },
      rows() {
        return this.pickLists.length;
      },
      user() {
        return this.$store.getters['auth/getActiveUser'];
      },
      // Create an options list from our fields
      sortOptions() {
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      // Create sorting direction that can be saved in session
      directionOptions() {
        return this.directions
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
    },
    created() {
      document.title = "Recoil | Tai Sin";
    },
    mounted() {
      // this.picklistLineCounter = this.getPicklistLineCounter();
      this.picklistNoCounter = this.getPicklistNoCounter();

      this.$store
        .dispatch("order/fetchOrder")
        .catch((err) => console.log(err));
      this.$store
        .dispatch('auth/fetchUser')
        .catch(err => console.log(err));
      // this.$store.dispatch("picklist/getPicklist")
  
      // Saving Menu Setting on localstorage session so it still same even after reloading the page
      if (this.$session.has("perPageQuotationCommerce")) {
        this.perPage = this.$session.get("perPageQuotationCommerce")
      }
      if (this.$session.has("sortByQuotationCommerce")) {
        this.sortBy = this.$session.get("sortByQuotationCommerce")
      }
      if (this.$session.has("sortDescQuotationCommerce")) {
        this.sortDesc = this.$session.get("sortDescQuotationCommerce")
      }

      this.queryRecoil();
    },
    watch: {
      // Taking the Menu Setting from localstorage session so the setting will be the same as before
      perPage(perPageNew) {
       this.$session.set("perPageQuotationCommerce", perPageNew)
      },
      sortBy(sortByNew) {
        this.$session.set("sortByQuotationCommerce", sortByNew)
      },
      sortDesc(sortDescNew) {
        this.$session.set("sortDescQuotationCommerce", sortDescNew)
      },
    },
  
    methods: {
      ...mapActions({
        getPicklist: "picklist/getPickListRecoil",
      }),

      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
      },

      queryRecoil(){
        this.getPicklist({
          entry : this.perPage,
          page : this.currentPage,
        }).then((data) => {
          // console.log("data", data)
          this.processMetadata(data.metadata)
        }).catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },

      onPageChange(page) {
        this.currentPage = page;
        this.queryRecoil();
      },
      // add spinner while loading on searching data process
      debounceSearch(event) {
        this.showSpinner = true
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.showSpinner = false
          this.search = event.target.value
        }, 600)
      },
      multipleDelete() {
        this.promptMultipleDelete = false;
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected.length >= 0) {
            this.deleteId = this.selected[i].id;
            this.confirmDelete();
          }
        }
      },
      onRowSelected(items) {
        this.selected = items;
      },

      uploadButton() {
        this.promptUpload = true;
        this.title = 'Upload Recoil';
      },

      storePicklistNoCounter(counter) {
        localStorage.setItem('picklistNoCounter', counter.toString());
      },

      getPicklistNoCounter() {
        const counter = localStorage.getItem('picklistNoCounter');
        return counter ? parseInt(counter) : 1;
      },

      generatePicklistLine() {
        const uniquePicklistLine = 1

        return uniquePicklistLine;
      },

      generatePicklistNo() {
        const lastPicklistNo  = this.picklistNoCounter;
        this.picklistNoCounter++;

        this.storePicklistNoCounter(this.picklistNoCounter);
        const newPicklistNo = 'R' + ('000000' + lastPicklistNo).slice(-6);

        return newPicklistNo;
      },

      async uploadItem() {
        this.uploadItemDisabled = true;
        
        // Delete all picklist from upload recoil that props pickable
        try {
          await this.$store.dispatch("picklist/deletePickableUploadRecoilPicklist").then(() => {
            this.$bvToast.toast(`Successfully deleted picklist`, {
              title: 'Success',
              variant: 'success',
              solid: true,
            });
            // Upload items one by one
            this.uploadCounter = 0;
            this.promptUpload = false;
            this.promptLoading = true;
          
            this.uploadItems();
          })
        } catch (err) {
          this.$bvToast.toast('cannot process deleted picklist', {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
      
        
      },

      //method for uploading recoil
      async uploadItems () {
            if (this.uploadCounter === 0) {
            this.promptUpload = false;
            this.promptLoading = true;
            }

            if (this.uploadCounter < this.uploadLength) {
              setTimeout(async () => {
                var data = this.uploadArray[this.uploadCounter];

                const trimmedData = Object.entries(data).reduce((acc, curr) => {
                  let [key, value] = curr;
                  // Checking if the key is a string
                  acc[typeof key === "string" ? key.trim() : key] = value;
                  return acc;
                }, {});

                this.addModal.picklistLine = this.generatePicklistLine();
                this.addModal.orderDate = trimmedData['ARR_DATE'];
                this.addModal.pickListType = "R";
                this.addModal.sendType = "N";
                this.addModal.quantity = trimmedData['Qty'];
                this.addModal.toBatch = "10808S/205W";
                this.addModal.fromBatch = trimmedData['DRUM(Batch)'];
                this.addModal.orderHandler = "NANCY";
                this.addModal.lineItem = trimmedData['ITEM_CODE']
                this.addModal.lineDescription = `${trimmedData['GROUP(Type)']}, ${trimmedData['SIZE']}, ${trimmedData['COLOUR Code']}`;
                this.addModal.unit = trimmedData['UNIT'];
                this.addModal.stockBalance = 0;
                this.addModal.binLocation = trimmedData['LTBINL(Bin Location)'];
                this.addModal.warehouse = trimmedData['WHS(Warehouse Location)'];
                this.addModal.companyName = "Taisin"
                this.addModal.customerCode = "112233"
                this.addModal.picklistPrintDate = "20231128 143010"

                this.uploadCounter++;
                // method add
                await this.addRecoil()
                await this.uploadItems()
                
              }, 50)
            } else {
              this.file = null;
              this.promptLoading = false;
              this.queryRecoil()
              this.uploadItemDisabled = false;
            }    
      },

      // getDeletedPicklist() {
      //   const deletedPicklist = this.$store.getters["picklist/getPicklist"]
      //     .filter(data => {
      //       return data.props.status == "pickable" && data.pickListType == "R" && data.picklistLine[0] != "R";
      //     })
      //     .map(data => {
      //       return data.id ;
      //     });
      //   return deletedPicklist;
      // },

      async addRecoil() {
        var formTemp = {...this.addModal};
        formTemp.props.app = this.$session.get('phibase-app');
        formTemp.props.status = "pickable"
        formTemp.props.statusNo = 0
        // formTemp.deletedPicklist = []
        let done = this.uploadCounter === this.uploadLength

        await this.$store
          .dispatch("picklist/uploadRecoil", formTemp)
          .then(() => {
            if(done == true){
              this.$bvToast.toast(`Successfully Uploaded ${this.uploadLength} Recoil(s)`, {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
              // this.queryRecoil()
            }
          })
          .catch((err) => {
            if(done == true){
              this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
              });
            }
          });
      },

      previewFiles (e) {
        this.errorMessage = '';
        let files = e.target.files, f = files[0]
        const reader = new FileReader()
        reader.onload = function (e) {
          const data = new Uint8Array(e.target.result)
          const workbook = XLSX.read(data, {type: 'array'})
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {raw: false, defval: ''})

          const acceptedColumnName = [
            'ITEM_CODE',
            'GROUP(Type)',
            'SIZE',
            'COLOUR Code',
            'DRUM(Batch)',
            'Qty',
            'UNIT',
            'LTBINL(Bin Location)',
            'ARR_DATE', 
            'WHS(Warehouse Location)'
          ]

          const columnName = Object.keys(XL_row_object[0]);

          for (let i = 0; i < acceptedColumnName.length; i++) {
            if (columnName[i] !== acceptedColumnName[i]) {
              this.errorMessage = 'Invalid column name'
              return;
            }
          }

          this.uploadCounter = 0;
          this.uploadLength = XL_row_object.length;
          this.uploadArray = XL_row_object;
        }.bind(this)
        reader.readAsArrayBuffer(f)
      },

      getFormattedDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${month}${day}${year}`;
      },

      getFormattedSequence() {
        const sequenceNumber = this.sequence.toString().padStart(4, '0');
        this.sequence++; // Tambahkan 1 ke SEQUENCE
        return sequenceNumber;
      },

      //Reject Order
      reject(data) {
        let newItem = data.item.map((el) => {
              return {
                detail: el.detail.id,
                note: el.note,
                quantity: el.quantity,
                drum: el.drum,
              };
            });
        let sendStatus = {
          ...data,
          item : newItem,
          props: {
                needApproval: data.props.needApproval,
                status: "Recoil Rejected",
                createdBy: this.user,
                app: this.$session.get('phibase-app')
              },
        };
        this.$store.dispatch("order/updateOrder", {id:sendStatus.id, data:sendStatus} )
          .then(() => {
            this.$bvToast.toast("Successfully change status to 'Recoil Rejected'", {
            title: "Success",
            variant: "success",
            solid: true,
            });
            this.$store
            .dispatch("order/fetchOrder")
            .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      },
  
      //Approve Order
      approve(data) {
        let newItem = data.item.map((el) => {
              return {
                detail: el.detail.id,
                note: el.note,
                quantity: el.quantity,
                drum: el.drum,
              };
            });
        let sendStatus = {
          ...data,
          item : newItem,
          props: {
                needApproval: data.props.needApproval,
                status: "Recoil Approved",
                createdBy: this.user,
                app: this.$session.get('phibase-app')
              },
        };
        this.$store.dispatch("order/updateOrder", {id:sendStatus.id, data:sendStatus} )
          .then(() => {
            this.$bvToast.toast("Successfully change status to 'Recoil Approved'", {
            title: "Success",
            variant: "success",
            solid: true,
            });
            this.$store
            .dispatch("order/fetchOrder")
            .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      },
      deleteRequest(id) {
        this.deleteId = id;
        this.promptDelete = true;
      },
      confirmDelete() {
        this.promptDelete = false;
        this.$store
          .dispatch("order/deleteOrder", { id: this.deleteId })
          .then(() => {
            this.$bvToast.toast("Successfully delete recoil", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.$store
              .dispatch("order/fetchOrder")
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            console.log(err.response.data.errors);
            this.$bvToast.toast(err.response.data.errors[0].message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
      },
    },
    destroyed() {},
  };
  </script>
  <style>
  @media (min-width: 761px) {
    .show-on-mobile { display: none !important; }
  }
  @keyframes spinner {
    0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
  .spin::before {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
    border: solid 5px #cfd0d1;
    border-bottom-color: #0077B3;
    border-radius: 50%;
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: 20px;
    transform: translate3d(-50%, -50%, 0);
    will-change: transform;
  }
  </style>